import React, { Component } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from '../components/section';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

class Platform extends Component {

  render() {
    return (
      <Layout {...this.props} transparentHeader>
        <SEO title="Partner" />
        <Section style={{paddingLeft: 0, paddingRight: 0, position: 'relative'}}>
          <img src={require('../images/network.jpg')} style={{opacity: 0.3, position: 'absolute'}}/>
          <Row style={{paddingLeft: 48, paddingRight: 48, marginTop: 128}}>
            <div style={{flex: 2}}>
              <h1>
                Partner network
              </h1>
            </div>
          </Row>
        </Section>
      </Layout>
    );
  }

}

export default Platform;
