import React, { Component } from 'react';
import styled, {keyframes} from 'styled-components';

const FadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(.99);
  };
  100% {
    opacity: 1;
    transform: scale(1);
  };
`;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  animation-name: '${FadeIn}';
  animation-duration: .6s;
  padding-left: 48px;
  padding-right: 48px;
  animation-fill-mode: forwards;
  opacity: 0;
  animation-play-state: ${props => props.animState};
`;


const Child = styled.div`
    animation-name: '${FadeIn}';
    animation-play-state: ${props => props.animState};
    animation-fill-mode: forwards;
    opacity: 0;
    animation-duration: .6s;
    animation-delay: ${props => `${props.animDelay}s`};
`

class Section extends Component {

  state = {
    animState: 'paused',
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.isVisible);
    this.isVisible();
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.isVisible);

  }

  isVisible = () => {
      if (!this.ref){
        return false;
      }

      const rect = this.ref.getBoundingClientRect();
      const isVisible = rect.top <= 400 && rect.bottom >= 0;

      if (isVisible && this.state.animState === 'paused'){
        this.onVisible();
      }
  }

  onVisible = () => {
    if (this.props.onVisible){
      this.props.onVisible();
    }

    this.setState({
      animState: 'running'
    })

  }

  render() {
    return (
      <Container
        ref={r => this.ref = r}
        style={{...this.props.style}}
        animState={this.state.animState}
        >
        {Array.isArray(this.props.children) && this.props.children.map((c, i) => {
          return (
            <Child
              key={`child_${i}`}
              animDelay={0.2*i}
              animState={this.state.animState}
              >
              {c}
            </Child>
          )
        }) ||
        this.props.children
      }
      </Container>
    );
  }

}

export default Section;
